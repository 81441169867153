<template>
  <TTView>
    <VRow>
      <VCol>
        <TopicCard :topic="topic" />
      </VCol>
    </VRow>
  </TTView>
</template>
<script>
import TopicCard from '../../../components/multi-level-test/topics/TopicCard.vue';

export default {
  name: 'TestsTopicsView',
  components: {
    TopicCard,
  },

  data() {
    return {
      loading: false,
      topic: {},
    };
  },

  computed: {
    testId() {
      return this.$route.params.testId;
    },

    topicId() {
      return this.$route.params.topicId;
    },
  },

  async created() {
    await this.fetch();
  },

  methods: {
    async fetch() {
      try {
        this.loading = true;

        const { topicId } = this;
        const data = { id: topicId };

        const { topic } = await this.$di.api.MultiLevelTest.TopicsById({ data });

        this.topic = topic;
      } catch (err) {
        this.$di.notify.errorHandler(err);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
